<template>
  <CRow>
    <div>
      <CModal
          :title="
          submitType === 'Update'
            ? $lang.feedbacks.crud.edit
            : $lang.feedbacks.crud.view
        "
          size="lg"
          :show.sync="largeModal"
          :no-close-on-backdrop="true"
          color="primary"
      >
        {{ feedbackMessage }}

        <template #footer style="display: none">
          <CButton
              @click="darkModal = false"
              color="danger"
              style="display: none"
          >Discard
          </CButton
          >
          <CButton
              @click="darkModal = false"
              color="success"
              style="display: none"
          >Accept
          </CButton
          >
        </template>
      </CModal>
    </div>

    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.feedbacks.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Feedbacks')"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <!--                <BeforeFilter-->
                <!--                  :filterShow="toggleFilterValue"-->
                <!--                  @getFilterToggle="switchFilter"-->
                <!--                ></BeforeFilter>-->
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <!--        {{ showAlert }}-->
        <!--        <alerts :message="alertMessage" type="success" :v-if="showAlert"/>-->
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">

              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton
                      color="danger"
                      size="sm"
                      @click="resetFilter()"
                      v-c-tooltip="$lang.common.filter.button.cancel"
                  >
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>

          <CAlert
              :color="messageColor"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert>
          <v-server-table
              :columns="columns"
              :url="listUrl"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Feedbacks')"
          >
            <template #userName="data">
              <Avatar
                  :image="data.row.avatar"
                  :defaultImage="data.row.avatar"
                  :content="data.row.userName"
                  :length="50"
              />
            </template>
            <template #verified="data">
              <CBadge :color="statusBudget(data.row.verified)">{{ data.row.verified }}</CBadge>
            </template>
            <template #createdAt="data">
              {{ dateTimeUTCtoLocalFormat(data.row.createdAt) }}
            </template>
            <template #actions="data">
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.view_user"
                  v-on:click="viewTableRow(data.row.userId,data.row.type,data.row.dataId)"
              >
                <i class="fas fa-user"></i>
              </CButton>

              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.view_feedback"
                  v-on:click="viewFeedback(data.row.id)"
              >
                <i class="fas fa-eye"></i>
              </CButton>

              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-profile')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {feedbacks, city, country, state, users} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "/src/components/Avatar";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "FeedbackItem",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
    Avatar,
  },
  data() {
    return {
      messageColor: "success",
      feedbackMessage: "",
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false /* for filter toggle*/,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      selectedIndustry: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      userOptions: [],
      profileVerifiedOptions: [],
      activePage: 1,
      listUrl: "/feedbacks/list",
      module: feedbacks,
      moduleUser: users,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,
      feedbacks: {
        messae: "",
      },
      columns: [
        "userName",
        "type",
        "message",
        "verified",
        "createdAt",
        "actions",
      ],
      data: [],
      options: {
        headings: {
          userName: this.$lang.feedbacks.table.company,
          message: this.$lang.feedbacks.table.message,
          type: this.$lang.feedbacks.table.type,
          createdAt: this.$lang.common.table.createdAt,
          verified: this.$lang.feedbacks.table.verified,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name", "isVerified"],
        sortable: ["name", "isVerified"],
        filterable: ["name", "isVerified"],
      },
    };
  },
  mounted() {
    let self = this;
    //self.data = this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;

    localStorage.removeItem("iAmUser"); // if user then i will hide some information

    self.profileVerifiedOptions.push(
        {value: "1", label: "Pending"},
        {value: "2", label: "Verified"},
        {value: "3", label: "Blocked"}
    );

    axios.get(this.listUrlApi(this.moduleUser)).then((response) => {
      response.data.data.map(function (value, key) {
        self.userOptions.push({value: value.id, label: value.name});
      });
    });

    axios.get(this.listUrlApi(this.moduleCountry)).then((response) => {
      response.data.data.map(function (value, key) {
        self.countryOptions.push({value: value.id, label: value.name});
      });
    });

    this.$root.$on("alert", (arg1, arg2) => {
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    viewFeedback(id) {
      this.$router.push({path: `/feedbacks/view/`+id});
    },

    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    //Filer
    loadState() {
      let self = this;
      const postData = {countryId: self.feedbacks.country.value};
      axios
          .post(this.listUrlApi(this.moduleState), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadCity() {
      let self = this;
      const postData = {stateId: self.feedbacks.state.value};
      axios
          .post(this.listUrlApi(this.moduleCity), postData)
          .then((response) => {
            self.cityOptions = [];
            response.data.data.map(function (value, key) {
              self.cityOptions.push({value: value.id, label: value.name});
            });
          });
    },
    getSubIndustry() {
      let self = this;
      if (self.feedbacks.industry.value > 0) {
        const postData = {industry_id: self.feedbacks.industry.value};
        axios
            .post(this.listUrlApi(this.moduleIndustry), postData)
            .then((response) => {
              self.subIndustryOptions = [];
              response.data.data.map(function (value, key) {
                if (value.industryId > 0) {
                  self.subIndustryOptions.push({
                    value: value.id,
                    label: value.SubIndustry,
                  });
                }
              });
            });
      }
    },
    //END

    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if accepted
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.dismissCountDownS = 10;
                  self.messageColor = "success";
                  // self.$router.go()
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    createTableRow() {
      this.$router.push({name: "Profile Create"});
    },
    editTableRow(item) {
      this.$router.push({path: `profile/edit/${item}`});
    },
    viewTableRow(userId,type,dataId) {
      if(type == 'Company')
      {
        this.$router.push({path: `profile/view/${userId}`});
      }
      if(type == 'Student')
      {
        this.$router.push({path: `student/view/${userId}`});
      }
      if(type == 'College')
      {
        this.$router.push({path: `college/view/${dataId}/${userId}`});
      }
    },
    viewAllTableRow(item) {
      this.$router.push({path: `profile/all/${item}`});
    },
    verificationTableRow(item) {
      this.$router.push({path: `profile/verification/${item}`});
    },
    profileUpdateRequestListTableRow(item) {
      this.$router.push({path: `profile/update-requests/${item}`});
    },

    viewSocialLinkTableRow(item) {
      this.$router.push({path: `profile/social-links/${item}`});
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              self.data = response.data;
            } else {
              self.data = [];
            }
          });
    },

    applyFilter() {
      let industry = "";
      let user = "";
      let country = "";
      let state = "";
      let city = "";
      let profileVerified = "";

      let getSubIndustries = [];

      if (this.feedbacks.industry != null) {
        industry = this.feedbacks.industry.value;
      }
      if (this.feedbacks.subIndustry != null) {
        this.feedbacks.subIndustry.map(function (value, key) {
          getSubIndustries[key] = value.value;
        });
      }
      if (this.feedbacks.user != null) {
        user = this.feedbacks.user.value;
      }
      if (this.feedbacks.country != null) {
        country = this.feedbacks.country.value;
      }
      if (this.feedbacks.state != null) {
        state = this.feedbacks.state.value;
      }
      if (this.feedbacks.city != null) {
        city = this.feedbacks.city.value;
      }
      if (this.feedbacks.profileVerified != null) {
        profileVerified = this.feedbacks.profileVerified.value;
      }
      let reqObj = {
        industry: industry,
        subIndustry: getSubIndustries,
        user: user,
        country: country,
        state: state,
        city: city,
        profileVerified: profileVerified,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },

    resetFilter() {
      this.feedbacks.industry = "";
      this.feedbacks.subIndustry = "";
      this.feedbacks.user = {};
      this.feedbacks.country = {};
      this.feedbacks.state = {};
      this.feedbacks.city = {};
      this.feedbacks.profileVerified = {};
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
  },
};
</script>
